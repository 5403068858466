exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-facebook-video-downloader-js": () => import("./../../../src/pages/facebook-video-downloader.js" /* webpackChunkName: "component---src-pages-facebook-video-downloader-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-instagram-video-downloader-js": () => import("./../../../src/pages/instagram-video-downloader.js" /* webpackChunkName: "component---src-pages-instagram-video-downloader-js" */),
  "component---src-pages-likee-video-downloader-js": () => import("./../../../src/pages/likee-video-downloader.js" /* webpackChunkName: "component---src-pages-likee-video-downloader-js" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-reddit-video-downloader-js": () => import("./../../../src/pages/reddit-video-downloader.js" /* webpackChunkName: "component---src-pages-reddit-video-downloader-js" */),
  "component---src-pages-soundcloud-music-downloader-js": () => import("./../../../src/pages/soundcloud-music-downloader.js" /* webpackChunkName: "component---src-pages-soundcloud-music-downloader-js" */),
  "component---src-pages-tiktok-video-downloader-js": () => import("./../../../src/pages/tiktok-video-downloader.js" /* webpackChunkName: "component---src-pages-tiktok-video-downloader-js" */),
  "component---src-pages-twitter-video-downloader-js": () => import("./../../../src/pages/twitter-video-downloader.js" /* webpackChunkName: "component---src-pages-twitter-video-downloader-js" */),
  "component---src-pages-youtube-video-downloader-js": () => import("./../../../src/pages/youtube-video-downloader.js" /* webpackChunkName: "component---src-pages-youtube-video-downloader-js" */)
}

